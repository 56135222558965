import React from "react";

const Footer = () => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3 p-5 footer-main">
      <p className="text-white">
      ©️ 2024 All Rights Reserved. OSICrew - <a target="_blank" href="https://www.osireklamajansi.com/">Ankara Reklam Ajansı</a>
      
      </p>
      <a href="https://www.google.com/partners/agency?id=6388762230" target="_blank">
    <img src="https://www.gstatic.com/partners/badge/images/2024/PartnerBadgeClickable.svg" alt="osi reklam ajansı google partner"/>
</a>

    </div>
  );
};

export default Footer;
