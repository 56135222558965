import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/createWithUs.css";
import img1 from "../assets/images/ciftay/ciftay_logo.png";
import img2 from "../assets/images/ciftay/logo.png";
import img3 from "../assets/images/ciftay/ciftay_color.png";
import img4 from "../assets/images/ciftay/ciftay-typ.png";
import img5 from "../assets/images/ciftay/ciftay-typ2.png";
import img6 from "../assets/images/ciftay/ciftay_card.png";
import img7 from "../assets/images/ciftay/ciftay_folder.png";
import img8 from "../assets/images/ciftay/ciftay_notebook.png";
import img9 from "../assets/images/ciftay/ciftay-clo.png";
import img10 from "../assets/images/enerjey/enerjey.png";
import img11 from "../assets/images/enerjey/enerjey-logo-2.png";
import img12 from "../assets/images/enerjey/enerjey-palette.png";
import img13 from "../assets/images/enerjey/enerjey_logo.png";
import img14 from "../assets/images/enerjey/enerjey-website.png";
import img15 from "../assets/images/enerjey/enerjey_letter.png";
import img16 from "../assets/images/enerjey/enerjey-cup.png";
import img17 from "../assets/images/crowne/crowne_pattern.png";
import img18 from "../assets/images/crowne/crowne-logo.png";
import img19 from "../assets/images/crowne/crowne-color.png";
import img20 from "../assets/images/crowne/crowne-stuff.png";
import img21 from "../assets/images/crowne/crowne-tentcard.png";
import img22 from "../assets/images/crowne/crowne-social.png";
import img23 from "../assets/images/nook/nook-logo.png";
import img24 from "../assets/images/nook/nook-palette.png";
import img25 from "../assets/images/nook/nook-pattern.png";
import img26 from "../assets/images/nook/nook-letter.png";
import img27 from "../assets/images/nook/nook-typ.png";
import img28 from "../assets/images/nook/nook-stuff.png";
import img29 from "../assets/images/nook/nook-kitch.png";
import img30 from "../assets/images/nook/nook-clo.png";
import img31 from "../assets/images/ciftay/ciftay-book.png";
import img37 from "../assets/images/crowne/crowne.png";
import img38 from "../assets/images/nook/nook.png";
import img39 from "../assets/images/crowne/crowne-main.png";
import img40 from "../assets/images/nook/nook-main.png";
import Fade from "react-reveal/Fade";

import { AiFillCloseCircle } from "react-icons/ai";
import { IoChevronForwardCircle } from "react-icons/io5";

const CreateWithUsTwo = ({ onSlideChange, scrollRefTwo }) => {
  const containerRefCiftay = useRef(null);
  const containerRefEnerjey = useRef(null);
  const containerRefCrowne = useRef(null);
  const containerRefNook = useRef(null);
  const multiplier = 6.5;
  const [allowNavigation, setAllowNavigation] = useState(false);

  const handleWheelScrollTwo = (event) => {
    const delta = event.deltaX || event.detail || -event.wheelDelta;

    containerRefCiftay.current.scrollRight -= delta * multiplier;
    containerRefEnerjey.current.scrollRight -= delta * multiplier;
    containerRefCrowne.current.scrollRight -= delta * multiplier;
    containerRefNook.current.scrollRight -= delta * multiplier;

    event.preventDefault();
  };

  const handleContainerIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.scrollLeft = 0;
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleContainerIntersect, {
      threshold: 0.5, // Adjust as needed
    });

    observer.observe(containerRefCiftay.current);
    observer.observe(containerRefEnerjey.current);
    observer.observe(containerRefCrowne.current);
    observer.observe(containerRefNook.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const swiperRefTwo = useRef(null);
  const handleSVGClick = () => {
    setAllowNavigation(true);
    if (swiperRefTwo.current && swiperRefTwo.current.swiper) {
      swiperRefTwo.current.swiper.slideTo(1);

      setTimeout(() => {
        onSlideChange(1);
        // setAllowNavigation(false);
      }, 100); // 2 saniye sonra onSlideChange(1) çalıştırılır
    }
  };
  const handleSVGClickEnerjey = () => {
    setAllowNavigation(true);
    if (swiperRefTwo.current && swiperRefTwo.current.swiper) {
      swiperRefTwo.current.swiper.slideTo(2);

      setTimeout(() => {
        onSlideChange(2);
        // setAllowNavigation(false);
      }, 100);
    }
  };
  const handleSVGClickCrowne = () => {
    setAllowNavigation(true);
    if (swiperRefTwo.current && swiperRefTwo.current.swiper) {
      swiperRefTwo.current.swiper.slideTo(3);

      setTimeout(() => {
        onSlideChange(3);
        // setAllowNavigation(false);
      }, 100);
    }
  };
  const handleSVGClickNook = () => {
    setAllowNavigation(true);
    if (swiperRefTwo.current && swiperRefTwo.current.swiper) {
      swiperRefTwo.current.swiper.slideTo(4);

      setTimeout(() => {
        onSlideChange(4);
        // setAllowNavigation(false);
      }, 100);
    }
  };
  const handleSVGReset = () => {
    setAllowNavigation(true);
    if (swiperRefTwo.current && swiperRefTwo.current.swiper) {
      swiperRefTwo.current.swiper.slideTo(0);
      onSlideChange(0);
    }
    setTimeout(() => {
      setAllowNavigation(false);
    }, 1000); // 1000ms (1 second) later, setAllowNavigation(false) will be called
    setTimeout(() => {
      if (scrollRefTwo && scrollRefTwo.current) {
        // Scroll to the top of the "CreateWithUs" component container
        scrollRefTwo.current.scrollIntoView({ behavior: "smooth" });
        onSlideChange(0);
        swiperRefTwo.current.swiper.slideTo(0);
      }
    }, 10); // 1000ms (1 second) later, setAllowNavigation(false) will be called
  };

  return (
    <div
      className="create_main text-white  justify-content-center align-items-center "
      ref={scrollRefTwo}
    >
      <Swiper
        className="mySwiper"
        ref={swiperRefTwo}
        autoplay={false}
        // allowSlidePrev={allowNavigation}
        // allowSlideNext={allowNavigation}
        initialSlide={0}
      >
        {/* card start*/}
        <SwiperSlide>
          <div className="container text-white container-grid-second ">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 ">
                <div className="row card-mobile ">
                  <Fade bottom>
                    {" "}
                    <div
                      className=" col-lg-12 flex-column d-flex justify-content-center bg-black margin-div"
                      onClick={handleSVGClick}
                    >
                      <div className="d-flex justify-content-end position-relative">
                        {" "}
                        <img
                          className="main-crowne"
                          src={img31}
                          alt="Image"
                        ></img>
                        <div
                          className="overlay-text-creative d-flex align-items-start
                   justify-content-end"
                        >
                          <h6>
                            <h2>
                              <b>ciftay</b>
                            </h2>
                            ads <br /> social media
                            <br /> brand id <br />
                            product design
                          </h6>
                        </div>{" "}
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                          onClick={handleSVGClick}
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>{" "}
                  </Fade>
                  <Fade bottom>
                    {" "}
                    <div
                      className=" col-lg-12 d-flex flex-column justify-content-center bg-black margin-div"
                      onClick={handleSVGClickCrowne}
                    >
                      <div
                        className="d-flex justify-content-start
                     position-relative"
                      >
                        {" "}
                        <img
                          className="main-crowne"
                          src={img39}
                          alt="Image"
                        ></img>
                        <div
                          className="overlay-text-crowne d-flex align-items-end
                   justify-content-start"
                        >
                          <h6>
                            <h2>
                              <b>crowne</b>
                            </h2>
                            logo <br /> brand id <br /> web design <br />
                            social media <br /> animation <br /> shooting <br />{" "}
                            ads
                          </h6>
                        </div>{" "}
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="col-lg-7 ml-4">
                <div className="row card-mobile">
                  <Fade bottom>
                    {" "}
                    <div className=" col-lg-12 flex-column d-flex justify-content-center bg-black margin-div">
                      <div
                        className="d-flex justify-content-center position-relative"
                        onClick={handleSVGClickEnerjey}
                      >
                        {" "}
                        <img src={img13} alt="Image"></img>
                        <div
                          className="overlay-text-creative d-flex align-items-start
                   justify-content-end"
                        >
                          <h6>
                            <h2>
                              <b>enerjey</b>
                            </h2>
                            ads <br /> social media
                            <br /> brand id <br />
                            product design
                          </h6>
                        </div>{" "}
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                          onClick={handleSVGClickEnerjey}
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>{" "}
                  </Fade>
                  <Fade bottom>
                    {" "}
                    <div
                      className=" col-lg-12 d-flex flex-column justify-content-center bg-black margin-div"
                      onClick={handleSVGClickNook}
                    >
                      <div className="position-relative">
                        {" "}
                        <div
                          className="overlay-text-creative  d-flex 
                   justify-content-start"
                          style={{ top: "0vh" }}
                        >
                          <h6 className="">
                            <h2>
                              <b>nook</b>
                            </h2>
                            logo <br /> brand id <br /> web design <br />
                            social media <br /> animation <br /> shooting <br />{" "}
                            ads
                          </h6>
                        </div>{" "}
                        <img
                          className="main-nook"
                          src={img40}
                          alt="Image"
                        ></img>
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                          onClick={handleSVGClickNook}
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* card end */}

        {/* Ciftay start */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefCiftay}
              onWheel={handleWheelScrollTwo}
            >
              <header style={{ marginTop: "-12vh" }}>
                <div className="container m-5">
                  <img className="ciftay-logo " src={img1}></img>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-ciftay">
                        <h6>
                          With its deep-rooted company experience, strong
                          financial structure <br />
                          and innovative perspective, Çiftay makes a name for
                          itself in sectors <br />
                          such as Mining, Contracting, Energy, Construction and
                          Tourism, <br />
                          renewing its logo and corporate identity, which uses
                          its reliability, <br /> durability and high energy,
                          and operates within its structure.
                          <br /> It has also aimed to bring all group companies
                          under a single <br />
                          visual identity to carry communication in a stronger
                          way.
                        </h6>
                      </div>
                    </div>
                  </div>

                  <img className="ciftay-logo m-3" src={img2}></img>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-ciftay">
                        <h6>
                          The combination of blue and red colors used in its new
                          corporate <br /> identity symbolizes its style, and
                          its uniquely created font <br />
                          symbolizes its strong stance.
                        </h6>
                        <h6>
                          Red represents passion, power and enthusiasm; It is
                          used as the <br />
                          primary color choice in the new corporate identity as
                          a symbol
                          <br /> of excitement and high energy. With its dynamic
                          structure, it
                          <br /> represents initiative, leadership and
                          experience.
                        </h6>
                        <h6>
                          The side color preference, which contains the messages
                          of <br />
                          reliability and strength, is blue, symbolizing wisdom,
                          <br /> determinationand strength. It also symbolizes
                          success as <br />a symbol of eternity, authority and
                          efficiency.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <main role="main">
                <section id="about " className="w-90">
                  <img className="ciftay-color" src={img3}></img>
                </section>
                <section className="d-block w-150  mt-4">
                  <div className="container d-flex mobile-center-scroll">
                    <div className="col-lg-5 ">
                      <div>
                        <img className="ciftay-foo" src={img4}></img>
                      </div>

                      <h6>
                        The combination of blue and red colors used in its new
                        corporate identity symbolizes
                        <br /> its style, and its uniquely created font
                        symbolizes its strong stance.
                      </h6>

                      <h6>
                        Red represents passion, power and enthusiasm; It is used
                        as the primary color choice in the <br /> new corporate
                        identity as a symbol of excitement and high energy. With
                        its dynamic structure, <br /> it represents initiative,
                        leadership and experience.
                      </h6>
                      <h6>
                        {" "}
                        The side color preference, which contains the messages
                        of reliability and strength, is blue, <br /> symbolizing
                        wisdom, determination and strength. It also symbolizes
                        success as a symbol <br /> of eternity, authority and
                        efficiency.
                      </h6>

                      <img className="ciftay-typ" src={img5}></img>
                    </div>
                  </div>
                </section>

                <section className="w-25 " id="about ">
                  <img className="stuff_ciftay" src={img6}></img>
                </section>
                <section className="w-25" id="about ">
                  <img className="stuff_ciftay" src={img7}></img>
                </section>
                <section className="w-25" id="about ">
                  <img className="stuff_ciftay" src={img8}></img>
                </section>
                <section className=" w-25" id="about ">
                  <img className="stuff_ciftay" src={img9}></img>
                </section>

                <section className="  mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* Ciftay end */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefEnerjey}
              onWheel={handleWheelScrollTwo}
            >
              <header>
                <div className="container m-4">
                  <div className="row">
                    <img className="enerjey-logo mb-5" src={img10}></img>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="">
                        <h6>
                          ENERJEY was established by MIA TEKNOLOJI Inc., which
                          is a <br />
                          publicly listed (BIST:MIATK) leading company providing
                          software <br />
                          solutions, and RENSOL ENERJİ Inc. that has been giving
                          <br /> consultancy services in the energy sector.With
                          our headquarter <br /> in Ankara, Turkey and the
                          regional offices in Qatar and in the U.S. <br /> we
                          have been providing Project Development, EPC
                          Contracting <br /> and Operation & Maintenance
                          Services for Solar Projects and Battery <br /> Energy
                          Storage Systems as well as AI based software solutions
                          and <br />
                          Investments in the renewable energy industry.Our
                          professionals <br />
                          having profound experience in the energy industry have
                          successfully <br />
                          contributed to
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <main role="main">
                <section
                  id="about "
                  className="w-90 mt-5"
                  style={{ display: "block" }}
                >
                  <div className="row mb-3">
                    {" "}
                    <img className="enerjey2-logo " src={img11}></img>
                  </div>
                  <div className="row">
                    {" "}
                    <h6>
                      While creating ENERJEY's logo, the focus was on
                      symbolizing solar <br />
                      panels to convey the company's values. The design
                      showcased solar <br /> panel motifs, highlighting its
                      commitment to renewable energy and <br /> sustainable
                      practices. The design aimed to align with the company's{" "}
                      <br />
                      identity, featuring a distinctive look that echoed
                      ENERJEY's modern
                      <br /> and forward-thinking approach. The color palette
                      and typography
                      <br /> reflected the company's leadership and innovation
                      in the energy <br /> sector. The orange dot within the 'J'{" "}
                      symbolized battery technology
                      <br /> and energy storage, showcasing the company's
                      expertise. The vibrant
                      <br /> orange color added dynamism and energy to the logo,
                      capturing its <br /> impactful nature.
                    </h6>
                    <h6>
                      In essence, the ENERJEY logo told a symbolic story,
                      representing <br /> renewable energy and expertise in
                      energy storage. This design <br /> encapsulated the
                      company's vision and values succinctly.
                    </h6>
                  </div>{" "}
                </section>
                <section className="d-block">
                  <div className="container ">
                    <div>
                      <img className="nook-palette" src={img12}></img>
                    </div>
                  </div>
                </section>

                <section className="w-25 " id="about ">
                  <img className="stuff_enerjey" src={img14}></img>
                </section>
                <section className="w-25 ml-4" id="about ">
                  <img className="stuff_enerjey" src={img15}></img>
                </section>
                <section className="w-25" id="about ">
                  <img className="stuff_enerjey" src={img16}></img>
                </section>
                <section className=" w-25" id="about ">
                  <img className="stuff_enerjey" src={img13}></img>
                </section>
                <section className=" w-25" id="about ">
                  <img className="stuff_enerjey" src={img10}></img>
                </section>

                <section className="mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* lamots start */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefCrowne}
              onWheel={handleWheelScrollTwo}
            >
              <main role="main">
                <section
                  id="about "
                  className="w-90"
                  style={{ display: "block", marginTop: "15vh" }}
                >
                  <div className="row">
                    {" "}
                    <img className="crowne-pattern " src={img17}></img>
                  </div>
                </section>
                <section className="d-block">
                  <div className="container ">
                    <div>
                      <img
                        style={{ maxWidth: "80%", marginTop: "20vh" }}
                        src={img19}
                      ></img>
                    </div>
                  </div>
                </section>

                <section className="w-50 " id="about ">
                  <img className="crowne_brand" src={img20}></img>
                </section>
                <section className="w-25 ml-13" id="about ">
                  <img className="stuff_crowne" src={img21}></img>
                </section>
                <section className="w-150" id="about ">
                  <img
                    className="stuff_crowne"
                    style={{ marginTop: "-28vh" }}
                    src={img22}
                  ></img>
                </section>

                <section className="mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* lamots end */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefNook}
              onWheel={handleWheelScrollTwo}
            >
              <header className="m-4" style={{ display: "block" }}>
                <div className="container m-5">
                  <div className="row">
                    <img className="nook-logo mb-2" src={img2}></img>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <div className="">
                        <h6>
                          Nook only the seekers can find. Nook welcomes you
                          delicately with its <br />
                          contemporary style. Here, you can enjoy the fine wine
                          and good spirits
                          <br /> in the intimate atmosphere. Whenever you need
                          to sit back and take <br />a breath to escape the
                          stress of reality. Nook offers you a taste of heaven,
                          <br />a taste you’ll remember.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      {" "}
                      <img className="nook-logo-2" src={img23}></img>
                    </div>
                    <div className="col-lg-6">
                      {" "}
                      <div style={{ textAlign: "end" }}>
                        <h6>
                          We aimed to create a minimal and impressive logo,
                          <br />
                          emphasizing that it is a place that offers comfort,
                          <br />
                          quality and intimacy to its guests, reflecting the
                          <br />
                          word "nook" meaning "corner, nook" and the
                          <br /> meaning of its name in geometric letters.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mt-2">
                      <div className="">
                        <h6>
                          While emphasizing the elegant and modern atmosphere of
                          the space with <br /> geometric letters, straight
                          lines and sharp corners; The simplicity of the logo
                          <br />
                          reflects the bar's vision of providing quality service
                          and comfort.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <main role="main">
                <section id="about " className="w-90 nook-type">
                  <div className="row mb-3">
                    {" "}
                    <img className="nook-logo" src={img24}></img>
                  </div>
                  <div className="row">
                    {" "}
                    <h6>
                      Care was taken to reflect the spirit of the design in the
                      selected <br /> colors. While the dynamic and lively tones
                      used serve the warm <br />
                      and inviting atmosphere of the place, the meaning of the
                      word <br />
                      "nook" is emphasized with the geometric shapes in the{" "}
                      font.
                    </h6>
                  </div>{" "}
                  <div className="row mb-5">
                    {" "}
                    <img style={{ maxWidth: "65%" }} src={img25}></img>
                  </div>
                </section>
                <section className="d-block w-50">
                  <div className="container ">
                    <div>
                      <img className="nook-typ" src={img26}></img>
                    </div>
                  </div>
                </section>

                <section className="d-block">
                  <div className="container ">
                    <div>
                      <img className="nook-font" src={img27}></img>
                    </div>
                  </div>
                </section>
                <section className="w-25 ml-13" id="about ">
                  <img className="stuff_nook" src={img28}></img>
                </section>
                <section className="w-25" id="about ">
                  <img className="stuff_nook" src={img29}></img>
                </section>
                <section className=" w-25" id="about ">
                  <img className="stuff_nook" src={img30}></img>
                </section>

                <section className="mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default CreateWithUsTwo;
