import React, { useState, useRef, useEffect } from "react";
import "../assets/css/career.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.css";

const CareerComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [isSuccessArray, setIsSuccessArray] = useState([false, false, false]);

  const [userInfo, setUserInfo] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubject] = useState("design");
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState("");

  const [isNameVisible, setIsNameVisible] = useState(true);
  const [isSubjectVisible, setIsSubjectVisible] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(false);
  const [isSendVisible, setIsSendVisible] = useState(false);

  useEffect(() => {
    // Sayfa yüklendiğinde sadece isNameVisible'i görünür hale getirin
    setIsNameVisible(true);
  }, []);

  useEffect(() => {
    setIsNameVisible(true);
    setIsSubjectVisible(false);
    setIsEmailVisible(false);
    setIsPhoneVisible(false);
    setIsMessageVisible(false);
    setIsFileVisible(false);
    setIsSendVisible(false);
  }, [activeTab]);

  const handleSubjectInputChange = (e) => {
    setSubject(e.target.value);
  };
  const handleNameInputChange = (e) => {
    setUserInfo(e.target.value);
    setIsEmailVisible(true);
  };
  const handleEmailInputChange = (e) => {
    setFrom(e.target.value);
    setIsPhoneVisible(true);
  };

  const handlePhoneInputChange = (e) => {
    setTel(e.target.value);
    setIsMessageVisible(true);
  };

  const handleMessageInputChange = (e) => {
    setMessage(e.target.value);
    setIsFileVisible(true);
  };

 

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFiles(selectedFile);
    setIsSendVisible(true);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    setClickCount(0);
    if (index === 0) {
      setSubject("design");
    } else if (index === 1) {
      setSubject("development");
    } else if (index === 2) {
      setSubject("production");
    }
  };

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleClickButton = async (e, tabIndex) => {
    e.preventDefault();
  
    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();
    
    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);
    FD.append('ownerName', 'nurullah@osicrew.com');
    FD.append('to', 'nurullah@osicrew.com');
    FD.append('host', 'smtp.gmail.com');
    FD.append('service', 'gmail');
  
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", FD, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        const updatedSuccessArray = [...isSuccessArray];
        updatedSuccessArray[tabIndex] = true;
        setIsSuccessArray(updatedSuccessArray);
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, { autoClose: 4500 });
    }
  };
  

  return (
    <>
      <div className="container career-main toastify-message h_100 text-white d-flex justify-content-center align-items-center flex-column">
        <ToastContainer />
        <div className="col-lg-8 ">
          {" "}
          <Fade bottom>
            <h3 className="career_text">join our team</h3>
            <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
              <TabList>
                <Tab>
                  <Fade right delay={300}>
                    <h6
                      className={`career_button ${
                        activeTab === 0 ? "active" : ""
                      }`}
                    >
                      design
                    </h6>
                  </Fade>
                </Tab>
                <Tab>
                  <Fade right delay={400}>
                    <h6
                      className={`career_button ${
                        activeTab === 1 ? "active" : ""
                      }`}
                    >
                      development
                    </h6>
                  </Fade>
                </Tab>
                <Tab>
                  <Fade right delay={500}>
                    <h6
                      className={`career_button ${
                        activeTab === 2 ? "active" : ""
                      }`}
                    >
                      production
                    </h6>
                  </Fade>
                </Tab>
              </TabList>

              <TabPanel>
                <form
                  className="col-10"
                  action
                  ref={form}
                  onSubmit={handleClickButton}
                  encType="multipart/form-data"
                >
                  <input
                    id="name"
                    name="userInfo"
                    type="text"
                    required
                    placeholder="name"
                    aria-required="true"
                    className="career_button_inner"
                    onChange={handleNameInputChange}
                    style={{ display: isNameVisible ? "block" : "none" }}
                  />
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    required
                    placeholder={subject}
                    aria-required="true"
                    value={subject}
                    // readOnly
                    onChange={handleSubjectInputChange}
                    className="career_button_inner"
                    style={{ display: isSubjectVisible ? "block" : "none" }}
                  />
                  <input
                    id="email"
                    name="from"
                    type="text"
                    required
                    placeholder="email"
                    aria-required="true"
                    onChange={handleEmailInputChange}
                    className="career_button_inner"
                    style={{ display: isEmailVisible ? "block" : "none" }}
                  />
                  <input
                    id="phone"
                    name="tel"
                    required
                    type="tel"
                    placeholder="phone"
                    size={20}
                    aria-required="true"
                    onChange={handlePhoneInputChange}
                    className="career_button_inner"
                    style={{ display: isPhoneVisible ? "block" : "none" }}
                  />
                  <input
                    id="message"
                    name="message"
                    type="text"
                    required
                    placeholder="message"
                    aria-required="true"
                    onChange={handleMessageInputChange}
                    className="career_button_inner"
                    style={{ display: isMessageVisible ? "block" : "none" }}
                  ></input>
                  <input
                    type="file" 
                    name="file"
                    files={files}
                    onChange={handleFileInputChange}
                    className="career_button_inner"
                    style={{ display: isFileVisible ? "block" : "none" }}
                  ></input>
            <button
  value="submit"
  type="submit"
  onClick={(e) => handleClickButton(e, 0)} // Pass the tabIndex to identify the button
  className="send_button"
  style={{
    display: isFileVisible ? "block" : "none",
    pointerEvents: isSuccessArray[0] ? "none" : "auto",
    opacity: isSuccessArray[0] ? 0.5 : 1,
  }}
>
  send
</button>

                </form>
              </TabPanel>
              <TabPanel>
                <form
                  className="col-10"
                  action
                  ref={form}
                  onSubmit={handleClickButton}
                >
                  <input
                    id="name"
                    name="userInfo"
                    type="text"
                    required
                    placeholder="name"
                    aria-required="true"
                    className="career_button_inner"
                    onChange={handleNameInputChange}
                    style={{ display: isNameVisible ? "block" : "none" }}
                  />
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    required
                    placeholder={subject}
                    aria-required="true"
                    value={subject}
                    // readOnly
                    onChange={handleSubjectInputChange}
                    className="career_button_inner"
                    style={{ display: isSubjectVisible ? "block" : "none" }}
                  />
                  <input
                    id="email"
                    name="from"
                    type="text"
                    required
                    placeholder="email"
                    aria-required="true"
                    onChange={handleEmailInputChange}
                    className="career_button_inner"
                    style={{ display: isEmailVisible ? "block" : "none" }}
                  />
                  <input
                    id="phone"
                    name="tel"
                    required
                    type="tel"
                    placeholder="phone"
                    size={20}
                    aria-required="true"
                    onChange={handlePhoneInputChange}
                    className="career_button_inner"
                    style={{ display: isPhoneVisible ? "block" : "none" }}
                  />
                  <input
                    id="message"
                    name="message"
                    type="text"
                    required
                    placeholder="message"
                    aria-required="true"
                    onChange={handleMessageInputChange}
                    className="career_button_inner"
                    style={{ display: isMessageVisible ? "block" : "none" }}
                  ></input>
                  <input
                    type="file" // Change the input type to "file"
                    name="file"
                    onChange={handleFileInputChange}
                    className="career_button_inner"
                    style={{ display: isFileVisible ? "block" : "none" }}
                  ></input>
             <button
  value="submit"
  type="submit"
  onClick={(e) => handleClickButton(e, 1)} // Pass the tabIndex to identify the button
  className="send_button"
  style={{
    display: isFileVisible ? "block" : "none",
    pointerEvents: isSuccessArray[1] ? "none" : "auto",
    opacity: isSuccessArray[1] ? 0.5 : 1,
  }}
>
  send
</button>

                </form>
              </TabPanel>
              <TabPanel>
                <form
                  className="col-10"
                  action
                  ref={form}
                  onSubmit={handleClickButton}
                >
                  <input
                    id="name"
                    name="userInfo"
                    type="text"
                    required
                    placeholder="name"
                    aria-required="true"
                    className="career_button_inner"
                    onChange={handleNameInputChange}
                    style={{ display: isNameVisible ? "block" : "none" }}
                  />
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    required
                    placeholder={subject}
                    aria-required="true"
                    value={subject}
                    // readOnly
                    onChange={handleSubjectInputChange}
                    className="career_button_inner"
                    style={{ display: isSubjectVisible ? "block" : "none" }}
                  />
                  <input
                    id="email"
                    name="from"
                    type="text"
                    required
                    placeholder="email"
                    aria-required="true"
                    onChange={handleEmailInputChange}
                    className="career_button_inner"
                    style={{ display: isEmailVisible ? "block" : "none" }}
                  />
                  <input
                    id="phone"
                    name="tel"
                    required
                    type="tel"
                    placeholder="phone"
                    size={20}
                    aria-required="true"
                    onChange={handlePhoneInputChange}
                    className="career_button_inner"
                    style={{ display: isPhoneVisible ? "block" : "none" }}
                  />
                  <input
                    id="message"
                    name="message"
                    type="text"
                    required
                    placeholder="message"
                    aria-required="true"
                    onChange={handleMessageInputChange}
                    className="career_button_inner"
                    style={{ display: isMessageVisible ? "block" : "none" }}
                  ></input>
                  <input
                    type="file" // Change the input type to "file"
                    name="file"
                    onChange={handleFileInputChange}
                    className="career_button_inner"
                    style={{ display: isFileVisible ? "block" : "none" }}
                  ></input>
              <button
  value="submit"
  type="submit"
  onClick={(e) => handleClickButton(e, 2)} // Pass the tabIndex to identify the button
  className="send_button"
  style={{
    display: isFileVisible ? "block" : "none",
    pointerEvents: isSuccessArray[2] ? "none" : "auto",
    opacity: isSuccessArray[2] ? 0.5 : 1,
  }}
>
  send
</button>

                </form>
              </TabPanel>
            </Tabs>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default CareerComponent;

