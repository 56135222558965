/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FaBicycle, FaLanguage, FaHotel } from "react-icons/fa";
import { AiFillSafetyCertificate, AiOutlineClose } from "react-icons/ai";
import { CgMenuRight } from "react-icons/cg";
import { SlEnergy } from "react-icons/sl";
import { ChevronDownIcon } from "@heroicons/react/solid";
import osi from "../assets/images/osi_img.png";
import osi_logo from "../assets/images/osi_beyaz.svg";
import tripy_menu from "../assets/images/tripy_menu.png";
import lamots_menu from "../assets/images/lamots_menu.png";
import enerjey_menu from "../assets/images/enerjey_menu.png";
import crown_menu from "../assets/images/crowneplaza_menu.png";
import scrollGif from "../assets/images/scroll.gif";

const features = [
  {
    name: "Tripy",
    href: "#",
    description:
      "Get a better understanding of where your traffic is coming from.",
    icon: FaBicycle,
  },
  {
    name: "Lamots",
    href: "#",
    description: "Speak directly to your customers in a more meaningful way.",
    icon: FaLanguage,
  },
  {
    name: "Visox",
    href: "#",
    description: "Your customers' data will be safe and secure.",
    icon: AiFillSafetyCertificate,
  },
  {
    name: "Crowne",
    href: "#",
    description: "Connect with third-party tools that you're already using.",
    icon: FaHotel,
  },
  {
    name: "Enerjey",
    href: "#",
    description:
      "Build strategic funnels that will drive your customers to convert",
    icon: SlEnergy,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="relative ">
      <Popover className="relative bg_inner_header">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 px-6">
              <div className="flex justify-between items-center py-6">
                <div className="flex justify-start">
                  <a href="#">
                    <img className="h-8 w-auto h-10 " src={osi_logo} alt="" />
                  </a>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto inner_logo"
                          src={osi_logo}
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <AiOutlineClose
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {features.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-indigo-600 item_logo"
                              aria-hidden="true"
                            />
                            <span className="ml-3 item_name">{item.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      <main>
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center">
          <div className="px-4 px-8">
            <h1 className="text-4xl tracking-tight font-extrabold text-white text-5xl">
              <span className="block">
                Leave Your Mark Letter by Letter in the
              </span>{" "}
              <span className="block text-gray-600">Digital World</span>
            </h1>
            {/* <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 text-xl">
              Great Potential in a Single Line.
            </p> */}

            <div className=" flex justify-center">
              {" "}
              <img className="sc_gif" src={scrollGif}></img>
            </div>
          </div>
        </div>
        {/* <div className="relative w-full ">
          <img className="absolute  " src={osi} alt="" />
        </div> */}
        <div className="container">
          {/* <div className="col-lg-12 curve_height">
            {" "}
            <svg
              className="svg_text"
              id="svg"
              viewBox="0 20 1040 700"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="curve"
                d="M 0,7000 C 0,7000 0,350 -50,350 C 152.13333333333333,306 304.26666666666665,262 445,298 C 585.7333333333333,334 715.0666666666668,450 879,472 C 1042.9333333333332,494 1241.4666666666667,422 1440,350 C 1440,350 1440,700 1440,700 Z"
              />
              <text x={-2000}>
                <textPath className="text_fill" xlinkHref="#curve">
                  think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently
                  •think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently •
                  think and • see differently • think and • see differently •
                </textPath>
                <animate
                  attributeName="x"
                  dur="30s"
                  values="-4000;0"
                  repeatCount="indefinite"
                />
              </text>
            </svg>
          </div> */}
          <div className="row inner_menu_logo ">
            <div className="col-3 d-flex justify-content-center mt-5">
              <a href="https://www.tripy.mobi/" target="blank">
                {" "}
                <img src={tripy_menu}></img>
              </a>
            </div>
            <div className="col-3 d-flex justify-content-center mt-5">
              <a href="https://lamots.com/" target="blank">
                {" "}
                <img src={lamots_menu}></img>
              </a>
            </div>
            <div className="col-3 d-flex justify-content-center mt-5">
              <a href="https://www.enerjey.com/" target="blank">
                {" "}
                <img src={enerjey_menu}></img>
              </a>
            </div>{" "}
            <div className="col-3 d-flex justify-content-center mt-5">
              <a href="https://www.crowneplazaankarahotel.com" target="blank">
                {" "}
                <img src={crown_menu}></img>
              </a>
            </div>
            <p className="mt-3 text-white text-center">and more...</p>
          </div>
        </div>
      </main>
    </div>
  );
}
