import React, { useEffect, useRef, useState } from "react";
import "../assets/css/textSlider.css";
import { jarallax, jarallaxVideo } from "jarallax";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper/modules";
jarallaxVideo();

const TextVideoSlider = ({ onSwiperComplete }) => {
  const bgVideoWrapRef = useRef(null);
  const bgFlexRef = useRef(null);
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false);
  const componentRef = useRef(null);
  const [marginTopValue, setMarginTopValue] = useState(70);
  const [gradientOpacity, setGradientOpacity] = useState(0);

  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      // Only enable scrolling on mobile devices (width less than 768px)
      if (window.innerWidth <= 768) {
        const vh = window.innerHeight / 100;
        const scrollTop = document.documentElement.scrollTop;
        const start = 120 * vh;
        const stop = 150 * vh;

        if (scrollTop > start && scrollTop < stop) {
          const newScale = Math.max(1 - (scrollTop - start) / 400, 0.7);
          setScale(newScale);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      const scrollAmount = event.deltaY || event.touches[0].clientY - startY; // Check deltaY for wheel event or use touches for touchmove event
      if (scrollAmount < 0 && isLastSlideVisible) {
        setIsLastSlideVisible(false);
        return;
      }
      const newMarginTop =
        marginTopValue - scrollAmount * (scrollAmount > 0 ? 0.2 : 0.5);

      setMarginTopValue(Math.min(Math.max(newMarginTop, 0), 70));
    };

    const touchStart = (event) => {
      startY = event.touches[0].clientY; // Store the initial touch position
    };

    let startY = 0; // Variable to store the initial touch position

    if (isLastSlideVisible) {
      window.addEventListener("wheel", handleScroll);
      window.addEventListener("touchmove", handleScroll);
      // window.addEventListener("touchstart", touchStart);
    } else {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("touchstart", touchStart);
    }

    return () => {
      // Make sure to remove the event listeners when the component unmounts
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("touchstart", touchStart);
    };
  }, [isLastSlideVisible, marginTopValue, gradientOpacity]);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
      autoPlay: true,
      videoPlayOnlyVisible: true,
      videoLoop: true,
      controls: false,
    });
  }, []);

  useEffect(() => {
    let timeoutId;

    if (isLastSlideVisible) {
      timeoutId = setTimeout(() => {
        if (onSwiperComplete) {
          onSwiperComplete();
        }
      }, 100);
    }

    return () => clearTimeout(timeoutId);
  }, [isLastSlideVisible, onSwiperComplete]);

  const handleSwiperSlideChange = (swiper) => {
    const isLastSlide = swiper.isEnd && swiper.progress === 1;
    setIsLastSlideVisible(isLastSlide);
  };

  return (
    <div className="h_100 d-flex justify-content-center align-items-center">
      {" "}
      <div className="h_100 h_100_mobile text_slider_main" ref={bgFlexRef}>
        <div
          style={{ transform: `scale(${scale})` }}
          className="bg-video-wrap"
          ref={bgVideoWrapRef}
        >
          <div
            className="jarallax"
            data-video-src="https://www.youtube.com/watch?v=k6KOQiDkJzQ"
          ></div>

          <div className="text_slider" ref={bgFlexRef}>
            <Swiper
              direction={"vertical"}
              slidesPerView={1}
              spaceBetween={0}
              mousewheel={{
                invert: false,
                releaseOnEdges: true,
                forceToAxis: true,
                sensitivity: 1,
                thresholdDelta: 5,
                thresholdTime: 1000,
                friction: 0.5,
                freeMode: false,
                disableOnInteraction: true,
              }}
              modules={[Mousewheel, Pagination]}
              className="mySwiper"
              onSlideChange={handleSwiperSlideChange}
            >
              <SwiperSlide>
                <Fade bottom delay={50}>
                  we&nbsp;
                </Fade>
                <Fade bottom delay={100}>
                  set&nbsp;
                </Fade>
                <Fade bottom delay={150}>
                  the&nbsp;
                </Fade>
                <Fade bottom delay={200}>
                  trends.
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom delay={50}>
                  not&nbsp;
                </Fade>
                <Fade bottom delay={100}>
                  follow&nbsp;
                </Fade>
                <Fade bottom delay={150}>
                  it&nbsp;
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom delay={50}>
                  we&nbsp;
                </Fade>
                <Fade bottom delay={100}>
                  are&nbsp;
                </Fade>
                <Fade bottom delay={150}>
                  who&nbsp;
                </Fade>
                <Fade bottom delay={200}>
                  we&nbsp;
                </Fade>
                <Fade bottom delay={250}>
                  are.
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom delay={50}>
                  we&nbsp;
                </Fade>
                <Fade bottom delay={100}>
                  are&nbsp;
                </Fade>
                <Fade bottom delay={150}>
                  osi.&nbsp;
                </Fade>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextVideoSlider;
