import React, { useState, useRef, useEffect } from "react";
import "../assets/css/contact.css";
import our_video from "../assets/images/contact.mp4";
// import our_gif from "../assets/images/world.gif";
import Fade from "react-reveal/Fade";
import "react-tabs/style/react-tabs.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiSolidDirectionLeft } from "react-icons/bi";
import { SlEnvolopeLetter } from "react-icons/sl";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactComponent = () => {
  const videoRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [applyVideoFilter, setApplyVideoFilter] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [activeSectionIndex, setActiveSectionIndex] = useState(1); // Change this to 1
  const [activeTab, setActiveTab] = useState(0);

  const [subject, setSubject] = useState("design");

 
  const handleVideoPlay = () => {
    if (!videoStarted && videoRef.current) {
      videoRef.current.play();
      setVideoStarted(true);

      setTimeout(() => {
        setShowOverlay(true);
        setApplyVideoFilter(true);
      }, 3000);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleVideoPlay();
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const sections = [
    {
      title: "how can we help you?",
      content: "Content for 'How can we help you?'",
    },
    {
      title: "contact",
      content: "Content for 'Contact'",
    },
  ];

  const handleSectionClick = (index) => {
    setActiveSectionIndex(index);
  };

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("userInfo"),
      from: FD.get("from"),
      subject: FD.get("subject"),
      tel: FD.get("tel"),
      ownerName: "nurullah@osicrew.com",
      to: "nurullah@osicrew.com",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        setIsSuccess(true);
        console.log("dkda");
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      console.log(obj);
      toast.error(error.message, { autoClose: 4500 });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="contact_main d-flex justify-content-center align-items-center">
        <div className="bg-video-wrap-contact">
          <video
            className={`fullscreen-video video-web ${
              applyVideoFilter ? "filtered" : ""
            }`}
            src={our_video}
            muted
            autoPlay
            playsinline
            ref={videoRef}
          ></video>{" "}
          
          <div
            className={`overlay-div video-web col-12 ${
              showOverlay ? "visible" : ""
            }`}
          >
            <div className="acc_first ">
              <Fade left duration={4000}>
                {" "}
                <div className="accordion_div div_body text-white">
                  <div className="col-10">
                    {" "}
                    <ul>
                      {sections.map((section, index) => (
                        <li
                          key={index}
                          className={
                            index === activeSectionIndex ? "active" : ""
                          }
                          onClick={() => handleSectionClick(index)}
                        >
                          <div className="section-title">
                            <h2>{section.title}</h2>
                          </div>
                          <div
                            className={
                              index === activeSectionIndex
                                ? "section-content"
                                : ""
                            }
                          >
                            {index === activeSectionIndex && (
                              <>
                                {section.title === "how can we help you?" && (
                                  <div>
                                   
                                      <Fade left>
                                        {" "}
                                       
                                        <div className="d-flex justify-content-center ">
                                      
                                        
                                       
                                            <div className="col-lg-8 col-10">
                                              <div className="contact-page-form contact-form form-style-one wow fadeInUp delay-0-2s">
                                                <form
                                                  className="contactForm"
                                                  ref={form}
                                                  onSubmit={handleSubmit}
                                                >
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label htmlFor="name">
                                                          Full Name
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name="userInfo"
                                                          className="form-control"
                                                          placeholder="name"
                                                          required
                                                          data-error="Please enter your Name"
                                                        />
                                                        <label
                                                          htmlFor="name"
                                                          className="for-icon"
                                                        >
                                                          <i className="far fa-user" />
                                                        </label>
                                                        <div className="help-block with-errors" />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label htmlFor="email">
                                                          Email Address
                                                        </label>
                                                        <input
                                                          name="from"
                                                          className="form-control"
                                                          placeholder="e-mail"
                                                          required
                                                          data-error="Please enter your Email"
                                                        />
                                                        <label
                                                          htmlFor="email"
                                                          className="for-icon"
                                                        >
                                                          <i className="far fa-envelope" />
                                                        </label>
                                                        <div className="help-block with-errors" />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label htmlFor="phone">
                                                          Phone Number
                                                        </label>
                                                        <input
                                                          type="tel"
                                                          name="tel"
                                                          className="form-control"
                                                          placeholder="phone"
                                                          required
                                                          data-error="Please enter your Phone Number"
                                                        />
                                                        <label
                                                          htmlFor="phone"
                                                          className="for-icon"
                                                        >
                                                          <i className="far fa-phone" />
                                                        </label>
                                                        <div className="help-block with-errors" />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label htmlFor="subject">
                                                          Subject
                                                        </label>
                                                        <input
                                                          name="subject"
                                                          className="form-control"
                                                          placeholder="subject"
                                                          required
                                                          data-error="Please enter your Subject"
                                                        />
                                                        <label
                                                          htmlFor="subject"
                                                          className="for-icon"
                                                        >
                                                          <i className="far fa-text" />
                                                        </label>
                                                        <div className="help-block with-errors" />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="form-group">
                                                        <label htmlFor="message">
                                                          Message
                                                        </label>
                                                        <textarea
                                                          name="message"
                                                          id="message"
                                                          className="form-control"
                                                          rows={4}
                                                          placeholder="write message"
                                                          required
                                                          data-error="Please enter your Message"
                                                        />
                                                        <div className="help-block with-errors" />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="form-group mb-0">
                                                      <button
  type="submit"
  onSubmit={handleSubmit}
  className="send_button"
  disabled={isSuccess}
>
  Send Us a Message <i className="far fa-angle-right" />
</button>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </form>
                                              </div>
                                            </div>
                                    
                                        </div>
                                      </Fade>
                                 
                                  </div>
                                )}
                                {section.title === "contact" && (
                                  <Fade right>
                                    <div>
                                      <p>
                                        <b>Adress: </b> <br /> <br />
                                        Koç İkiz Kuleleri B Blok Kat:9
                                        <br />
                                        Söğütözü / ANKARA
                                        <br />
                                        <br />
                                        <b>Phone:</b>
                                        <br />
                                        <a href="tel:+903124902100">
                                          {" "}
                                          +90 312 490 21 00
                                        </a>
                                        <br />
                                        <br />
                                        <b>E-mail</b>
                                        <br />
                                        <a href="mailto:info@osireklamajansi.com">
                                          info@osireklamajansi.com
                                        </a>
                                      </p>
                                      <div className="contact-social mt-5">
                                        <div className="bg_social">
                                          <a
                                            href="https://www.google.com/maps/dir/39.9193904,32.7745914/Osi+Reklam+Ajans%C4%B1,+S%C3%B6%C4%9F%C3%BCt%C3%B6z%C3%BC+Mahallesi+S%C3%B6%C4%9F%C3%BCt%C3%B6z%C3%BC+Caddesi+Ko%C3%A7+%C4%B0kiz+Kuleleri+B+Blok+Kat:+9,+06520+%C3%87ankaya,+T%C3%BCrkiye/@39.9121523,32.6881725,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14d34f4d5fc9a45b:0x523e8d4486aa2b6b!2m2!1d32.8046122!2d39.9123338?entry=ttu"
                                            target="_blank"
                                          >
                                            <BiSolidDirectionLeft />
                                          </a>
                                        </div>{" "}
                                        <div className="bg_social">
                                          <a href="tel:+903124902100">
                                            {" "}
                                            <BsFillTelephoneFill />
                                          </a>
                                        </div>{" "}
                                        <div className="bg_social">
                                          <a href="mailto:info@osireklamajansi.com">
                                            {" "}
                                            <SlEnvolopeLetter />
                                          </a>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </Fade>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default ContactComponent;
