import logo from "./logo.svg";
import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./wrappers/Header";
import VideoComponent from "./components/VideoComponent";
import Dashboard from "./components/Dashboard";
import AOS from "aos";
import VideoAfterComponent from "./components/VideoAfterComponent";
import TextVideoSlider from "./components/TextVideoSlider";
import CareerComponent from "./components/CareerComponent";
import OurTech from "./components/OurTech";
import TextAnimation from "./components/TextAnimation";
import Deeneme from "./components/Deeneme"
import ContactComponent from "./components/ContactComponent";
import BuildWithUs from "./components/BuildWithUs";
import OurTechMobile from "./components/OurTechMobile";
import CreateWithUs from "./components/CreateWithUs";
import Test from "./components/Test";

function App() {
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.1/ScrollTrigger.min.js"
    );
  }, []);
  AOS.init();
  return (
    <>
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/test" element={<Deeneme />} />
          <Route exact path="/career" element={<CareerComponent />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
