import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/createWithUs.css";
import img1 from "../assets/images/121.png";
import img2 from "../assets/images/logo.svg";
import img3 from "../assets/images/logo_draft.svg";
import img4 from "../assets/images/img.png";
import img5 from "../assets/images/news.png";
import img6 from "../assets/images/01_paper.png";
import img7 from "../assets/images/02_card.png";
import img8 from "../assets/images/03_bag.png";
import img9 from "../assets/images/04_bottle.png";
import img10 from "../assets/images/color_01.svg";
import img11 from "../assets/images/color_02.svg";
import img12 from "../assets/images/mia_takvim.png";
import img13 from "../assets/images/lamots_card.png";
import img14 from "../assets/images/mia_logo.svg";
import img15 from "../assets/images/mia-palette.png";
import img16 from "../assets/images/mia-typ.png";
import img17 from "../assets/images/mia-letter.png";
import img18 from "../assets/images/mia-kart.png";
import img19 from "../assets/images/mia-folder.png";
import img20 from "../assets/images/mia-bag.png";
import img21 from "../assets/images/mia-cup.png";
import img22 from "../assets/images/mia-hoddie.png";
import img23 from "../assets/images/mia-social.png";
import img24 from "../assets/images/mia-solutions.png";
import img25 from "../assets/images/lamots/lamots.png";
import img26 from "../assets/images/lamots/lamots-palette.png";
import img27 from "../assets/images/lamots/lamots-journey.png";
import img28 from "../assets/images/lamots/lamots-folder.png";
import img29 from "../assets/images/lamots/lamots-notebook.png";
import img30 from "../assets/images/lamots/lamots-letter.png";
import img31 from "../assets/images/lamots/lamots-bag.png";
import img32 from "../assets/images/lamots/lamots-hoddie.png";
import img33 from "../assets/images/lamots/lamots-cube.png";
import img34 from "../assets/images/lamots/lamots-flash.png";
import img35 from "../assets/images/lamots/lamots-pin.png";
import img36 from "../assets/images/lamots/lamots-social.png";
import img37 from "../assets/images/triy_typ.png";

import Fade from "react-reveal/Fade";

import { AiFillCloseCircle } from "react-icons/ai";
import { IoChevronForwardCircle } from "react-icons/io5";

const CreateWithUs = ({ onSlideChange, scrollRef }) => {
  const containerRefTripy = useRef(null);
  const containerRefMia = useRef(null);
  const containerRefLamots = useRef(null);
  const multiplier = 14.5;
  const [allowNavigation, setAllowNavigation] = useState(false);

  const handleWheelScroll = (event) => {
    const delta = event.deltaX || event.detail || -event.wheelDelta;

    containerRefTripy.current.scrollRight -= delta * multiplier;
    containerRefMia.current.scrollRight -= delta * multiplier;
    containerRefLamots.current.scrollRight -= delta * multiplier;

    event.preventDefault();
  };

  const handleContainerIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.scrollLeft = 0;
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleContainerIntersect, {
      threshold: 0.5, // Adjust as needed
    });

    observer.observe(containerRefTripy.current);
    observer.observe(containerRefMia.current);
    observer.observe(containerRefLamots.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const swiperRef = useRef(null);
  const handleSVGClick = () => {
    setAllowNavigation(true);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(1);

      setTimeout(() => {
        onSlideChange(1);
        // setAllowNavigation(false);
      }, 100);
    }
  };

  const handleSVGClickMia = () => {
    setAllowNavigation(true);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(2);

      setTimeout(() => {
        onSlideChange(2);
        // setAllowNavigation(false);
      }, 100);
    }
  };
  const handleSVGClickLamots = () => {
    setAllowNavigation(true);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(3);

      setTimeout(() => {
        onSlideChange(3);
        // setAllowNavigation(false);
      }, 500);
    }
  };
  const handleSVGReset = () => {
    setAllowNavigation(true);
    if (swiperRef.current && swiperRef.current.swiper) {
      // swiperRef.current.swiper.slideTo(0);
      onSlideChange(0);
    }
    setTimeout(() => {
      setAllowNavigation(false);
    }, 1000);
    setTimeout(() => {
      if (scrollRef && scrollRef.current) {
        // Scroll to the top of the "CreateWithUs" component container
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
        // onSlideChange(0);
        swiperRef.current.swiper.slideTo(0);
      }
    }, 10); // 1000ms (1 second) later, setAllowNavigation(false) will be called
  };

  return (
    <div
      className="create_main text-white  justify-content-center align-items-center "
      ref={scrollRef}
    >
      <Swiper
        className="mySwiper"
        ref={swiperRef}
        autoplay={false}
        // allowSlidePrev={allowNavigation}
        // allowSlideNext={allowNavigation}
        initialSlide={0}
      >
        {/* card start*/}
        <SwiperSlide>
          <div className="container text-white container-grid-first">
            <div className="row d-flex justify-content-center">
              <Fade bottom>
                {" "}
                <div
                  className="col-lg-7 col-md-12 col-sm-10 col-xs-10 d-flex justify-content-center bg-black margin-div"
                  onClick={handleSVGClick}
                >
                  <div className="d-flex justify-content-center position-relative">
                    {" "}
                    <img src={img1} alt="Image"></img>
                    <div
                      className="overlay-text-creative d-flex align-items-start
                   justify-content-end"
                    >
                      <h6>
                        <h2>
                          <b>tripy</b>
                        </h2>
                        logo <br /> brand id <br /> web design <br />
                        product design
                      </h6>
                    </div>{" "}
                    <div
                      className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                    >
                      {" "}
                      <IoChevronForwardCircle className="circle_icon" />
                    </div>
                  </div>
                </div>
              </Fade>

              <div className="col-lg-4">
                <div className="row card-mobile">
                  <Fade bottom>
                    {" "}
                    <div
                      className=" col-lg-12 flex-column d-flex justify-content-center bg-black margin-div"
                      onClick={handleSVGClickMia}
                    >
                      <div className="d-flex justify-content-center position-relative">
                        {" "}
                        <img className="main-mia" src={img12} alt="Image"></img>
                        <div
                          className="overlay-text-creative d-flex align-items-start
                   justify-content-end"
                        >
                          <h6>
                            <h2>
                              <b>mia</b>
                            </h2>
                            ads <br /> social media
                            <br /> brand id <br />
                            product design
                          </h6>
                        </div>{" "}
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>{" "}
                  </Fade>
                  <Fade bottom>
                    {" "}
                    <div
                      className=" col-lg-12 d-flex flex-column justify-content-center bg-black margin-div"
                      onClick={handleSVGClickLamots}
                    >
                      <div className="d-flex justify-content-center position-relative">
                        {" "}
                        <img
                          style={{ maxWidth: "100%" }}
                          src={img13}
                          alt="Image"
                        ></img>
                        <div
                          className="overlay-text-creative d-flex align-items-start
                   justify-content-end"
                        >
                          <h6>
                            <h2>
                              <b>lamots</b>
                            </h2>
                            logo <br /> brand id <br /> web design <br />
                            social media <br /> animation <br /> shooting <br />{" "}
                            ads
                          </h6>
                        </div>{" "}
                        <div
                          className="overlay-text-creative d-flex align-items-end
                   justify-content-end"
                        >
                          {" "}
                          <IoChevronForwardCircle className="circle_icon" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* card end */}

        {/* tripy start */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefTripy}
              onWheel={handleWheelScroll}
            >
              <header>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text">
                        <h3>
                          <strong>tripy</strong>
                        </h3>
                        <h6>
                          tripy was established in partnership with MIA
                          Teknoloji to produce <br />
                          last-mile solutions with smart city and sharing
                          technologies.
                          <br /> Tripyaims to create a revolution in urban
                          transportation by <br /> supporting the freedom to
                          travel regardless of age, gender, social or <br />{" "}
                          economic status, adding value to social life in the
                          city with affordable,
                          <br /> quality services, and touching people’s lives
                          with alternative solutions
                          <br /> to fossil-fuel transportation to support a
                          sustainable world.
                        </h6>
                        <h6>
                          The tripy team, which wanted to introduce itself
                          abroad, needed <br /> a visual identity that better
                          reflected the high-quality services they <br /> offer.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <main role="main">
                <section id="about " className="w-90">
                  <img src={img1}></img>
                </section>
                <section className="w-100">
                  <div className="container mb-5 d-flex mobile-center-scroll">
                    <div className="col-lg-5 mr-5">
                      <div className="d-flex">
                        <img src={img2}></img>
                        <img className="ml-5" src={img3}></img>
                      </div>
                      <div className="">
                        <h3>
                          <strong>logo</strong>
                        </h3>
                        <h6>
                          Our task was to develop a new visual communication
                          strategy that <br /> reflected the company’s spirit
                          and strengthened its current image.
                        </h6>
                        <h6>
                          The tripy logo aims to emphasize the cute features of
                          the squirrel. <br />A friendly, social, fast, durable,
                          and agile animal, was deemed suitable
                          <br /> to reflect the company’s spirit in the new
                          visual communication <br />
                          strategy for tripy. An eye detail was added to
                          highlight that they are <br />
                          always alert. The location icon was emphasized in the
                          tail detail.
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-5 mt-5">
                      <div className="">
                        <h3>
                          <strong>color palette</strong>
                        </h3>
                        <h6>
                          Care was taken to ensure that the selected <br />
                          color was inspiring and dynamic. Using <br />a palette
                          of dynamic colors gives the tripy
                          <br /> brand a warm, friendly, and intimate character
                          <br />
                          that represents the spirit of the tripy team. <br />
                          The font was intended to be clear, legible, <br />
                          strong, and permanent.
                        </h6>
                      </div>
                      <div className="d-flex color-margin">
                        <div className="row mt-4">
                          <div className="col-lg-5 d-flex color-p-desc">
                            <img src={img10}></img>
                            <div className="d-block ml-5">
                              <h5>RGB</h5>
                              <h6>242 | 101 | 36</h6>
                              <h5>CMYK</h5>
                              <h6>0 | 75 | 98 | 0</h6>
                              <h5>PANTONE</h5>
                              <h6>P 34-8C</h6>
                            </div>
                          </div>
                          <div className="col-lg-5 d-flex color-p-desc">
                            <img src={img11}></img>
                            <div className="d-block ml-5">
                              <h5>RGB</h5>
                              <h6>242 | 101 | 36</h6>
                              <h5>CMYK</h5>
                              <h6>0 | 75 | 98 | 0</h6>
                              <h5>PANTONE</h5>
                              <h6>P 34-8C</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 d-flex justify-content-center rel-img">
                      <img className="max-img" src={img4}></img>
                    </div>

                    <div className="container font-container">
                      <div className="row font-exp">
                        <p className="font-text-desc">
                          typography | effra
                          <h5>
                            The font was intended to be clear,
                            <br />
                            legible, strong and permanent.
                          </h5>
                        </p>
                      </div>
                      <div className="row font-exp">
                        <img src={img37}></img>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="website w-150" id="about ">
                  <p className="web-text">website</p>
                  <img className="website-img" src={img5}></img>
                </section>
                <section className="website top-tripy w-25" id="about ">
                  <img src={img6}></img>
                </section>
                <section className="website top-tripy w-25" id="about ">
                  <img src={img7}></img>
                </section>
                <section className="website top-tripy  w-25" id="about ">
                  <img src={img8}></img>
                </section>
                <section className="website top-tripy w-25" id="about ">
                  <img src={img9}></img>
                </section>
                <section className="website top-tripy w-25 ml-5 " id="about ">
                  <img src={img2}></img>
                </section>
                <section className="website  mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* tripy end */}

        {/* mia start */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefMia}
              onWheel={handleWheelScroll}
            >
              <main>
                <section className="w-30 justify-content-start p-4 flex-initial ">
                  <div className="col-lg-4">
                    {" "}
                    <div className="row">
                      {" "}
                      <img className="mia-logo" src={img14}></img>
                    </div>
                    <div className="row mt-2">
                      {" "}
                      <img className="mia-palette" src={img15}></img>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-3 d-flex ">
                        <h3>vision</h3>
                      </div>
                      <div className="col-lg-9">
                        <h6 className="mision-text">
                          To be among the leading companies in Turkey and the
                          International Information Technology Sector <br />
                          by ensuring the satisfaction of our customers and
                          employees. To produce innovative solutions with <br />{" "}
                          high added value by means of Industry-Academy
                          Cooperation model and to compete with foreign
                          <br /> market actors.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <div className="row ">
                      {" "}
                      <img className="mia-typ" src={img16}></img>
                    </div>
                    <div className="row d-flex">
                      <img className="mia-logo" src={img17}></img>
                      <img className="mia-kart" src={img18}></img>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-5 d-flex mia-end">
                        <h3>mision</h3>
                      </div>
                      <div className="col-lg-7">
                        <h6 className="mision-text">
                          To provide solutions for different requests and needs
                          with its domestic and national solutions, range and,{" "}
                          <br />
                          wide product original products. To set an example in
                          customer satisfaction by providing high performance
                          <br /> and quality service. To produce IT projects
                          according to the needs of the enterprises as a
                          solution partner in
                          <br /> line with the new and inevitable realities{" "}
                          brought by the information age.
                        </h6>
                      </div>
                    </div>
                  </div>
                </section>
                <section className=" w-40 " id="about ">
                  <div className="row">
                    {" "}
                    <img
                      className="mia-stuff"
                      style={{ marginBottom: "3vh" }}
                      src={img19}
                    ></img>
                  </div>

                  <div className="row " style={{ marginBottom: "10vh" }}>
                    <div className="col-lg-5 d-flex justify-content-end">
                      <h3>our values</h3>
                    </div>
                    <div className="col-lg-7">
                      <h6 className="mision-text">
                        Quality products, services and fast support are MIA
                        Teknoloji’s <br />
                        original values.
                      </h6>
                    </div>
                  </div>
                </section>
                <section className=" w-40" id="about ">
                  <img className="mia-stuff" src={img20}></img>
                </section>
                <section className=" w-40" id="about ">
                  <img className="mia-stuff" src={img21}></img>
                </section>
                <section className=" w-40" id="about ">
                  <img className="mia-stuff" src={img12}></img>
                </section>
                <section className=" w-40" id="about ">
                  <img className="mia-hoddie" src={img22}></img>
                </section>{" "}
                <section className=" w-40" id="about ">
                  <img className="mia-stuff" src={img23}></img>
                </section>
                <section className="ml-5 w-40" id="about ">
                  <img className="mia-stuff" src={img24}></img>
                </section>
                <section className="mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* mia end */}
        {/* lamots start */}
        <SwiperSlide>
          <>
            <div
              className="end-ins"
              ref={containerRefLamots}
              onWheel={handleWheelScroll}
            >
              <main role="main">
                <section
                  id="about "
                  className="m-5"
                  style={{ display: "block" }}
                >
                  {" "}
                  <img className="lamots-inner-logo" src={img25}></img>
                </section>
                <section className="d-block w-150 ">
                  <div className="container ">
                    <div>
                      <img className="lamots-palette" src={img26}></img>
                    </div>
                  </div>
                </section>
                <section
                  className="d-block"
                  style={{ marginTop: "20vh" }}
                  id="about "
                >
                  <div className="row">
                    {" "}
                    <img style={{ maxWidth: "130%" }} src={img27}></img>
                  </div>

                  <div className="row mt-4">
                    <h6>
                      Lamots, known as the YEK METHOD; A methodology that
                      includes Linguistics and Neuroscience <br />
                      digital reflection. Continuing its education in Ankara,
                      the company decided to expand abroad. A <br /> stronger
                      visual identitywas needed to bring the high-level services
                      provided by the team
                      <br /> to wider audiences.
                    </h6>
                    <h6>
                      Our task was to present a new visual communication
                      strategy that reflects the spirit of <br />
                      the company and strengthens its current image. Lamots
                      would take the hassle out of language <br />
                      acquisition and reach many different audiences with its
                      authenticity.
                    </h6>
                    <h6>
                      We were asked to refer to the colors used in the previous
                      logo of the brand and to recreate its
                      <br /> corporate subtextwith a with a story.
                    </h6>
                  </div>
                </section>
                <section className="w-50 ml-5" id="about ">
                  <img className="stuff_lamots" src={img28}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img29}></img>
                </section>
                <section className="w-50 " id="about ">
                  <img className="stuff_lamots" src={img30}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img31}></img>
                </section>{" "}
                <section className="w-50" id="about ">
                  <img className="sstuff_lamots" src={img32}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img33}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img34}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img35}></img>
                </section>
                <section className="w-50" id="about ">
                  <img className="stuff_lamots" src={img36}></img>
                </section>
                <section className="mobile-none" id="about "></section>
              </main>
            </div>
            <div
              className="container close-button d-flex justify-content-center"
              onClick={handleSVGReset}
            >
              <AiFillCloseCircle />
            </div>
          </>
        </SwiperSlide>
        {/* lamots end */}
      </Swiper>
    </div>
  );
};

export default CreateWithUs;
