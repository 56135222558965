import React, { useRef, useEffect, useState } from "react";
import GlbPage from "./GlbPage";
import HereWeGo from "./HereWeGo";
import HereWeGoMobile from "./HereWeGoMobile";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import CareerComponent from "./CareerComponent";
import OurTech from "./OurTech";
import ContactComponent from "./ContactComponent";
import BuildWithUs from "./BuildWithUs";
import CreateWithUs from "./CreateWithUs";
import OurTechMobile from "./OurTechMobile";
import Test from "./Test";
import rotate from "../assets/images/rotate.png";
import CreateWithUsTwo from "./CreativeWithUsTwo";

const Dashboard = () => {
  const createWithUsRef = useRef(null);
  const createWithUsRefTwo = useRef(null);
  const hereWeGoRef = useRef(null);
  const hereWeGoRefMobile = useRef(null);
  const videoComponentRef = useRef(null);
  const ourTechRef = useRef(null);
  const careerRef = useRef(null);
  const contactRef = useRef(null);
  const [showComponents, setShowComponents] = useState(true);
  const [showComponentsTwo, setShowComponentsTwo] = useState(true);
  const [hereWeGoActive, setHereWeGoActive] = useState(false);
  const [hereWeGoMobileActive, setHereWeGoMobileActive] = useState(false);
  const [videoComponentActive, setVideoComponentActive] = useState(false);
  const [createWithUsActive, setCreateWithUsActive] = useState(false);
  const [ourTechActive, setourTechActive] = useState(false);
  const [careerActive, setCareerActive] = useState(false);
  const [contactActive, setContactActive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isLargeScreen = window.innerWidth > 992;

  const handleHereWeGoClick = () => {
    hereWeGoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleHereWeGoMobileClick = () => {
    hereWeGoRefMobile.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleBuildWithUsClick = () => {
    videoComponentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleCreateWithUsClick = () => {
    createWithUsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleOurTechClick = () => {
    ourTechRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleCareerClick = () => {
    careerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleContactClick = () => {
    contactRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleSlideChange = (index) => {
    if (index === 0) {
      setShowComponents(true); // Hide components when second slide is displayed
    } else {
      setShowComponents(false);
    }
  };

  const handleSlideChangeTwo = (index) => {
    if (index === 0) {
      setShowComponentsTwo(true); // Hide components when second slide is displayed
    } else {
      setShowComponentsTwo(false);
    }
  };

  const handleScrollGifClick = () => {
    // Here, scroll to the HereWeGo component when the GIF is clicked.
    hereWeGoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleScrollGifClickMobile = () => {
    // Here, scroll to the HereWeGo component when the GIF is clicked.
    hereWeGoRefMobile.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div>
      <div class="landscape-overlay">
        <p className="text-white">
          Please rotate your device to portrait mode.
        </p>
        <img src={rotate} alt="lamots"></img>
      </div>
      <Header
        hereWeGoActive={hereWeGoActive}
        hereWeGoMobileActive={hereWeGoMobileActive}
        videoComponentActive={videoComponentActive}
        createWithUsActive={createWithUsActive}
        ourTechActive={ourTechActive}
        careerActive={careerActive}
        contactActive={contactActive}
        onHereWeGoClick={handleHereWeGoClick}
        onHereWeGoMobileClick={handleHereWeGoMobileClick}
        onBuildWithUsClick={handleBuildWithUsClick}
        onCreateWithUsClick={handleCreateWithUsClick}
        onOurTechClick={handleOurTechClick}
        onCareerClick={handleCareerClick}
        onContactClick={handleContactClick}
      />
      <div
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <GlbPage onScrollGifClick={handleScrollGifClick} />
      </div>

      <div
        // className="here-we-go-main-web"
        ref={hereWeGoRef}
        onMouseEnter={() => setHereWeGoActive(true)}
        onMouseLeave={() => setHereWeGoActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <HereWeGo />
      </div>
      {/* <div
        ref={hereWeGoRefMobile}
        onMouseEnter={() => setHereWeGoActive(true)}
        onMouseLeave={() => setHereWeGoActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <HereWeGoMobile />
      </div> */}

      <div
        onMouseEnter={() => setHereWeGoActive(true)}
        onMouseLeave={() => setHereWeGoActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <Test />
      </div>

      <div
        ref={createWithUsRef}
        onMouseEnter={() => setCreateWithUsActive(true)}
        onMouseLeave={() => setCreateWithUsActive(false)}
        style={{ display: showComponentsTwo ? "block" : "none" }}
      >
        <CreateWithUs
          onSlideChange={handleSlideChange}
          scrollRef={createWithUsRef}
        />
      </div>
      <div
        ref={createWithUsRefTwo}
        onMouseEnter={() => setCreateWithUsActive(true)}
        onMouseLeave={() => setCreateWithUsActive(false)}
        style={{ display: showComponents ? "block" : "none" }}
      >
        <CreateWithUsTwo
          onSlideChange={handleSlideChangeTwo}
          scrollRefTwo={createWithUsRefTwo}
        />
      </div>

      <div
        ref={ourTechRef}
        onMouseEnter={() => setourTechActive(true)}
        onMouseLeave={() => setourTechActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <OurTech />
      </div>

      <div
        onMouseEnter={() => setourTechActive(true)}
        onMouseLeave={() => setourTechActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <OurTechMobile />
      </div>

      <div
        ref={videoComponentRef}
        onMouseEnter={() => setVideoComponentActive(true)}
        onMouseLeave={() => setVideoComponentActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <BuildWithUs />
      </div>

      <div
        ref={careerRef}
        onMouseEnter={() => setCareerActive(true)}
        onMouseLeave={() => setCareerActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <CareerComponent />
      </div>

      <div
        ref={contactRef}
        onMouseEnter={() => setContactActive(true)}
        onMouseLeave={() => setContactActive(false)}
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <ContactComponent />
      </div>

      <div
        style={{
          display: showComponents && showComponentsTwo ? "block" : "none",
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
