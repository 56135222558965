import React, { useEffect, useState, useRef } from "react";
import video from "../osicrew_macbook_02.mp4";
import videotwo from "../osicrew_macbook_02.mp4";
import IconWithWindow from "./IconWithWindow";
import icon_lamots from "../assets/images/lamots.svg";
import icon_enerjey from "../assets/images/enerjey.svg";
import icon_tripy from "../assets/images/tripy.svg";
import icon_visox from "../assets/images/visox.svg";
import window_lamots from "../assets/images/lamots_window.png";
import window_enerjey from "../assets/images/enerjey_window.png";
import window_visox from "../assets/images/visox_window.png";
import window_tripy from "../assets/images/tripy_window.png";
import "../assets/css/video.css";

function VideoComponent() {
  const [videoEnded, setVideoEnded] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    var vid = document.getElementById("v0");
    vid.pause();

    window.onscroll = function () {
      vid.pause();

      // Calculate the scroll position 200vh before the bottom of the page
      const targetScrollHeight =
        document.documentElement.scrollHeight -
        window.innerHeight -
        8 * window.innerHeight;

      // Check if the user scrolled to the target scroll position and videoEnded is false
      if (window.pageYOffset >= targetScrollHeight && !videoEnded) {
        setVideoEnded(true);
      }

      // Check if the user is scrolling back up and videoEnded is true, reset videoEnded to false
      if (window.pageYOffset < prevScrollY.current && videoEnded) {
        setVideoEnded(false);
      }

      prevScrollY.current = window.pageYOffset;
    };

    setInterval(function () {
      vid.currentTime = window.pageYOffset / 4000;
    }, 400);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.onscroll = null;
    };
  }, [videoEnded]);

  return (
    <div className="laptop_screen">
      <div id="set-height" />
      <p id="time" />
      <video
        id="v0"
        tabIndex={0}
        autobuffer="autobuffer"
        preload="preload"
        playsInline
      >
        <source src={video} type="video/webm" />
        <source type="video/webm" src={video} />
        <source type="video/mp4" src={videotwo} />
        <p>Sorry, your browser does not support the &lt;video&gt; element.</p>
      </video>
      {videoEnded && (
        <div className="container">
          <div className="row">
            <div id="video-overlay">
              <IconWithWindow iconSrc={icon_lamots} windowSrc={window_lamots} />
              <IconWithWindow
                iconSrc={icon_enerjey}
                windowSrc={window_enerjey}
              />
              <IconWithWindow iconSrc={icon_tripy} windowSrc={window_tripy} />
              <IconWithWindow iconSrc={icon_visox} windowSrc={window_visox} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoComponent;
