import React, { useEffect } from 'react'
import '../assets/css/Animation.css'
import bgVideo from '../assets/images/showreel_web_muzikli.mp4'

import { gsap } from "gsap"

    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { useLocation, useParams, Link } from "react-router-dom";import { jarallax, jarallaxVideo } from 'jarallax';
import 'jarallax/dist/jarallax.min.css';
jarallaxVideo();
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

const HereWeGo = () => {
 useEffect(() => {
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.2,
    videoSrc: 'https://www.youtube.com/watch?v=k6KOQiDkJzQ'
  });
}, [])
//gsap animation

// useEffect(() => {
// let tl = gsap.timeline({
//   scrollTrigger: {
//     trigger: '.animated-element',
//     start: 'top 20%',
//     end: 'bottom 20%',
//     scrub:false,
//     toggleActions: "play pause resume reverse reverse ",
//     markers: true,
//     //scrubs false yaptığımızda animasyonu durdurur bunuhn için toggleActions kullanıyoruz
//     //togleActions: "onEnter onLeave onEnterBack onLeaveBack"
//     //toggleActions: "play pause resume reverse complete  none"
//     //onEnter: animasyonu başlatır
//     //onLeave: animasyonu durdurur
//     //onEnterBack: animasyonu geri alır
//     //onLeaveBack: animasyonu geri alır
    
//   }
// });

// gsap.to(".animated-element", { x:800 duration: 2, });

// }, [])

useEffect(() => {
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.video-section',
    start: "25% center",
    end: "bottom top", 
    // scrub:true,
    scrub: 0.1,
    // toggleActions: "play pause resume reverse reverse ",
    //scrubs false yaptığımızda animasyonu durdurur bunuhn için toggleActions kullanıyoruz
    //togleActions: "onEnter onLeave onEnterBack onLeaveBack"
    //toggleActions: "play pause resume reverse complete  none"
    //onEnter: animasyonu başlatır
    //onLeave: animasyonu durdurur
    //onEnterBack: animasyonu geri alır
    //onLeaveBack: animasyonu geri alır
    
  }
});

tl.from(".text1", { y: 400, opacity: 0 ,duration: 4 });
tl.to(".text1", { y: 0, opacity: 1, duration: 4 });
tl.to(".text1", { y: -400, opacity: 0 , duration: 4});

tl.from(".text2", { y: 400, opacity: 0, duration: 4});
tl.to(".text2", { y: 0, opacity: 1, duration: 4 });
tl.to(".text2", { y: -400, opacity: 0 , duration: 4});

tl.from(".text3", { y: 400, opacity: 0, duration: 4 });
tl.to(".text3", { y: 0, opacity: 1, duration: 4 });
tl.to(".text3", { y: -400, opacity: 0, duration: 4 });

tl.from(".text4", { y: 400, opacity: 0, duration: 4 });
tl.to(".text4", { y: 0, opacity: 1, duration: 4 });
tl.to(".text4", { y: -400, opacity: 0, duration: 4 });

}, [])


//Smooth Scroll  
// const lenis = new Lenis()
// lenis.on('scroll', (e) => {
//   console.log(e)
// })
// function raf(time) {
//   lenis.raf(time)
//   requestAnimationFrame(raf)
// }
// requestAnimationFrame(raf)




  return (
    <>

      <div className="wrapss">
        <div className="zoomss">
          <div className="video-section">
            <div className="video-container">
            <div className="jarallax" data-jarallax data-video-src="https://www.youtube.com/watch?v=k6KOQiDkJzQ">
</div>

              <div className="video-text">
                <h2 className="text1 text-size  text-white">
                  <span>we set the trends</span>
                </h2>

                <h2 className="text2 text-size text-white">
                  <span>not follow it</span>
                </h2>

                <h2 className="text3 text-size text-white">
                  <span>we are who we are</span>
                </h2>

                <h2 className="text4 text-size text-white">
                  <span>we are osi</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sectionHeight last-section"></div>
    </>
  );
}

export default HereWeGo