import React, { useRef, useEffect } from "react";
import logo from "../assets/images/osi_beyaz.svg";
import "../assets/css/mobileMenu.css";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import Fade from "react-reveal/Fade";

const Header = ({
  hereWeGoActive,
  hereWeGoMobileActive,
  videoComponentActive,
  createWithUsActive,
  onHereWeGoMobileClick,
  ourTechActive,
  careerActive,
  contactActive,
  onHereWeGoClick,
  onBuildWithUsClick,
  onCreateWithUsClick,
  onOurTechClick,
  onCareerClick,
  onContactClick,
}) => {
  const checkboxRef = useRef(null);

  const closeMenu = () => {
    checkboxRef.current.checked = false;
  };
  const handleMenuItemClick = () => {
    closeMenu();
  };

  return (
    <>
      <div className="d-flex justify-content-center header_main">
        <div className="header">
          <div className="d-flex header-flex align-items-center ">
            <div
              className="header_padding"
              style={{
                color: hereWeGoActive ? "black" : "white",
                backgroundColor: hereWeGoActive ? "white" : "transparent",
              }}
              onClick={() => {
                onHereWeGoClick();
                onHereWeGoMobileClick();
              }}
            >
              #weare
            </div>
            <div
              className="header_padding"
              onClick={onCreateWithUsClick}
              style={{
                color: createWithUsActive ? "black" : "white",
                backgroundColor: createWithUsActive ? "white" : "transparent",
              }}
            >
              create with us
            </div>
            <div
              onClick={onOurTechClick}
              style={{
                color: ourTechActive ? "black" : "white",
                backgroundColor: ourTechActive ? "white" : "transparent",
              }}
              className="header_padding"
            >
              our technologies
            </div>
            <div className="header_img">
              <img src={logo} alt="logo" />
            </div>
            <div
              className="header_padding"
              style={{
                color: videoComponentActive ? "black" : "white",
                backgroundColor: videoComponentActive ? "white" : "transparent",
              }}
              onClick={onBuildWithUsClick}
            >
              build with us
            </div>
            <div
              onClick={onCareerClick}
              className="header_padding"
              style={{
                color: careerActive ? "black" : "white",
                backgroundColor: careerActive ? "white" : "transparent",
              }}
            >
              join our team
            </div>
            <div
              onClick={onContactClick}
              className="header_padding"
              style={{
                color: contactActive ? "black" : "white",
                backgroundColor: contactActive ? "white" : "transparent",
              }}
            >
              break the ice
            </div>
          </div>
        </div>
        <div className=" header_small">
          <div className="row text-white">
            <div className="col-lg-1">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>

      {/* mobile menü */}
      <nav className="navbar-mobile">
        <div className="navbar-container container-mobile">
          <input ref={checkboxRef} type="checkbox" name id />
          <div className="hamburger-lines">
            <span className="line line1" />
            <span className="line line2" />
            <span className="line line3" />
          </div>
          <ul className="menu-items-mobile">
            <Fade left delay={500}>
              {" "}
              <li className="mt-2">
                <a
                  className="header_padding"
                  style={{
                    color: hereWeGoActive ? "black" : "white",
                    backgroundColor: hereWeGoActive ? "white" : "transparent",
                  }}
                  onClick={() => {
                    onHereWeGoClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                >
                  #weare
                </a>
              </li>{" "}
            </Fade>
            <Fade left delay={800}>
              {" "}
              <li>
                <a
                  className="header_padding"
                  onClick={() => {
                    onCreateWithUsClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                  style={{
                    color: createWithUsActive ? "black" : "white",
                    backgroundColor: createWithUsActive
                      ? "white"
                      : "transparent",
                  }}
                >
                  create with us
                </a>
              </li>
            </Fade>
            <Fade left delay={1100}>
              {" "}
              <li>
                <a
                  onClick={() => {
                    onOurTechClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                  style={{
                    color: ourTechActive ? "black" : "white",
                    backgroundColor: ourTechActive ? "white" : "transparent",
                  }}
                  className="header_padding"
                >
                  our technologies
                </a>
              </li>
            </Fade>
            <Fade left delay={1400}>
              {" "}
              <li>
                <a
                  className="header_padding"
                  style={{
                    color: videoComponentActive ? "black" : "white",
                    backgroundColor: videoComponentActive
                      ? "white"
                      : "transparent",
                  }}
                  onClick={() => {
                    onBuildWithUsClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                >
                  build with us
                </a>
              </li>
            </Fade>
            <Fade left delay={1700}>
              {" "}
              <li>
                <a
                  onClick={() => {
                    onCareerClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                  className="header_padding"
                  style={{
                    color: careerActive ? "black" : "white",
                    backgroundColor: careerActive ? "white" : "transparent",
                  }}
                >
                  join our team
                </a>
              </li>
            </Fade>
            <Fade left delay={2100}>
              {" "}
              <li>
                <a
                  onClick={() => {
                    onContactClick();
                    handleMenuItemClick(); // Close the menu
                  }}
                  className="header_padding"
                  style={{
                    color: contactActive ? "black" : "white",
                    backgroundColor: contactActive ? "white" : "transparent",
                  }}
                >
                  break the ice
                </a>
              </li>
            </Fade>
            <Fade left delay={2500}></Fade>
            <div className="d-flex  social-mobile">
              <a
                target="_blank"
                href="https://www.facebook.com/people/osi-reklam-ajans%C4%B1/100063710493177/"
              >
                <AiFillFacebook />
              </a>
              <a href="https://www.instagram.com/weareosi/">
                {" "}
                <AiFillInstagram />
              </a>

              <a target="_blank" href="https://tr.linkedin.com/company/osicrew">
                <BiLogoLinkedin />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fosireklamajansi"
              >
                {" "}
                <AiOutlineTwitter />
              </a>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
