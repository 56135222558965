import React, { useState, useRef, useEffect } from "react";

import "../assets/css/ourtech.css";
import our_video from "../assets/images/01.mp4";
import ReactHtmlParser from "react-html-parser";
import app from "../assets/images/app.png";
import google from "../assets/images/app_2.png";
import Fade from "react-reveal/Fade";
import TextAnimation from "./TextAnimation";
const OurTech = () => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [slidePosition, setSlidePosition] = useState(0);
  const videoRef = useRef(null);

  // State to track if the video has started playing
  const [videoStarted, setVideoStarted] = useState(false);

  // Function to handle video play
  const handleVideoPlay = () => {
    if (!videoStarted && videoRef.current) {
      videoRef.current.play();
      setVideoStarted(true);
    }
  };

  useEffect(() => {
    // Intersection Observer setup
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Change this threshold as per your requirement
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // When the element is 50% visible on the screen, start playing the video
          handleVideoPlay();
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup the observer
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const sections = [
    {
      title: "hit he bot",
      content:
        "<div> <div>A breath of fresh air in the Brick Breaker category! 'Hit The Bot'. Put aside the ordinary bricks, control the ball, destroy the robot, splash the fun!</div> <div>Hit The Bot Features:</div>  <div>+ More than 1 Billion Robots</div> <div>+ Easy to play, hard to master</div> <div>+ Play offline anywhere</div> <div>Challange yourself to beat the highscore!</div> </div>",
      play: "https://play.google.com/store/apps/details?id=com.osicrew.hitthebot&hl=tr&gl=US",
      app: "https://apps.apple.com/lk/app/hit-the-bot-2d-brick-breaker/id1612447091",
    },
    {
      title: "monster dude",
      content:
        "Monster Dude is an amazing platform game with a lot of different worlds to play and so many monsters to play with. Pick your monster Dude and go on a memorable adventure. Explore different worlds and collect coins to get every monster.",
      play: "https://play.google.com/store/apps/details?id=com.osicrew.monsterdude",
      app: "https://apps.apple.com/tr/app/monster-dude/id1596774791",
    },
    {
      title: "para piyasam",
      content:
        "Keeping track of the markets and managing your savings is now very easy with the official app of parapiyasam.com, 'Para Piyasam.' With Para Piyasam, you can create a personal wallet and easily monitor your desired currencies, gold, stocks, commodities, cryptocurrencies, and other savings, along with their current values.",
      google:
        "https://play.google.com/store/apps/details?id=com.osicrew.parapiyasam",
      app: "https://apps.apple.com/tr/app/para-piyasam-bireysel-c%C3%BCzdan/id1606863861",
    },
    {
      title: "kinky",
      content:
        "Pick a category, enjoy peak fun with friends and partners! Our app suits every mood. Register, spin the wheel, and let the chosen name entertain you! It adds magic to any setting with 5 categories.",
      google:
        "https://play.google.com/store/apps/details?id=com.osicrew.drunkbottle",
      app: "https://apps.apple.com/tr/app/kinky-party-game/id1603640620",
    },
    {
      title: "nöbetci noter",
      content:
        "With this application, you can access the address, phone number, and other information of notary public offices on duty in all 81 provinces of Turkey with just one click. This application, available in over 40 cities, allows you to automatically find the nearest notary public offices to your location on the map and also provides directions.",
      google:
        "https://play.google.com/store/apps/details?id=com.osicrew.nobetcinoter",
      app: "https://apps.apple.com/tr/app/n%C3%B6bet%C3%A7i-noter/id1578663578",
    },
    {
      title: "deinos",
      content:
        "In this application, you will encounter a lot of questions about a variety of dinosaurs. If you want to refresh your knowledge and learn new facts, you will have a lot of fun playing the trivia game.",
      google: "",
      app: "https://apps.apple.com/tr/app/deinos-dinozor-bilgi-yar%C4%B1%C5%9Fmas%C4%B1/id1591608859",
    },
    {
      title: "boocard",
      content:
        "Create your business card and easily share it with NFC, QR, or your ID. If you update important information such as your address, phone number, and company name, everyone in your contact list will automatically receive the updates. Protect both nature and your information.",
      google:
        "https://play.google.com/store/apps/details?id=osi.crew.boocard&hl=tr&gl=US",
      app: "",
    },
  ];

  const handleSectionClick = (index) => {
    setActiveSectionIndex(index);
    setSlidePosition(index * 14 - 12 * index);
  };
  const handleSVGClick = () => {
    setSlidePosition(0);
  };
  return (
    <>
      {" "}
      {/* <TextAnimation /> */}
      <div className="our_tech_main d-flex justify-content-center align-items-center">
        <div className="bg-video-wrap-our">
          <video src={our_video} muted autoPlay ref={videoRef}></video>{" "}
          <div
            className="accordion_div div_body text-white"
            style={{ right: `${slidePosition}%` }}
          >
            {" "}
            {/* <div
              className="back_btn d-flex justify-content-center align-items-center"
              onClick={handleSVGClick}
            >
              <svg
                height="4.2vh"
                fill="#ffffff"
                viewBox="0 0 52 52"
                data-name="Layer 1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  {"{"}" "{"}"}
                  <g data-name="Group 132" id="Group_132">
                    {"{"}" "{"}"}
                    <path d="M38,52a2,2,0,0,1-1.41-.59l-24-24a2,2,0,0,1,0-2.82l24-24a2,2,0,0,1,2.82,0,2,2,0,0,1,0,2.82L16.83,26,39.41,48.59A2,2,0,0,1,38,52Z" />
                    {"{"}" "{"}"}
                  </g>
                  {"{"}" "{"}"}
                </g>
              </svg>
            </div> */}
            <div className="container"></div>
            <div className="col-lg-8">
              {" "}
              <ul>
                {sections.map((section, index) => (
                  <li
                    key={index}
                    className={index === activeSectionIndex ? "active" : ""}
                    onClick={() => handleSectionClick(index)}
                  >
                    <div
                      className={
                        index === activeSectionIndex ? "section-content" : ""
                      }
                    >
                      {index === activeSectionIndex && (
                        <>
                          <Fade right>
                            {" "}
                            <p>{ReactHtmlParser(section.content)}</p>
                            <div className="icon_container">
                              <a target="_blank" href={section.app}>
                                {" "}
                                <img
                                  className="main_icon"
                                  src={app}
                                  alt="App Icon"
                                />
                              </a>
                              <a target="_blank" href={section.app}>
                                {" "}
                                <img
                                  className="main_icon"
                                  src={google}
                                  alt="Google Icon"
                                />
                              </a>
                            </div>
                          </Fade>
                        </>
                      )}
                    </div>
                    <div className="section-title">
                      <h2>{section.title}</h2>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTech;
