import React, { useEffect } from "react"; // Import React and useEffect
import gsap from "gsap"; // Import the GSAP library
import ScrollTrigger from "gsap/ScrollTrigger"; // Import the ScrollTrigger plugin
import "../assets/css/test.css";
import sign from "../assets/images/signature.png";

gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin

const Test = () => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".g-scroll",
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
      })
      .fromTo(".bg", { y: 0 }, { y: "-75%" }, 0);
  }, []); // Empty dependency array means this effect runs once after initial render

  return (
    <div className="sc-gsap">
      <div className="g-wrap">
        {" "}
        {/* Use className instead of class */}
        <div className="text d-flex justify-content center align-items-center flex-column">
          {" "}
          {/* Use className instead of class */}
          Everything starts as an idea.&nbsp; The idea that brought us together
          started to shape in February 2011.&nbsp; That idea created who we are
          today and now, we create ideas.&nbsp; These let us pursue our dreams
          so that we can help you pursue yours.&nbsp; This mission carries
          utmost importance for we treat our clients as a part of our family. We
          believe that brand identity starts in mind and comes alive in
          design.&nbsp; Brand identity is a long journey but even the longest
          journeys start with baby steps. At each and every step, we are ride
          along you. Osi Advertising is where design and production are composed
          together because we focus on delivering you flawless results.&nbsp;
          Innovation is our stronghold while we build the future. We are the
          difference and we create the difference between typical and
          perfection.&nbsp;
          <img className="h-20 p-2" src={sign}></img>
          <div className="bg"></div> {/* Use className instead of class */}
        </div>
      </div>
      <div className="g-scroll"></div> {/* Use className instead of class */}
    </div>
  );
};

export default Test;
