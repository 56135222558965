import React, { useRef } from "react";
import gif from "../assets/images/alpha_reverse.gif";
import "../assets/css/glb.css";
import scrollGif from "../assets/images/scroll.gif";

const GlbPage = ({ onScrollGifClick }) => {
  // const hereWeGoRef = useRef(null);
  // const handleScrollGifClick = () => {
  //   hereWeGoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  // };
  return (
    <div className="h_100 d-flex  justify-content-center align-items-center flex-column">
      <div className="container d-flex justify-content-center align-items-center">
        <img className="glb_size " style={{ zIndex: "9999" }} src={gif}></img>
      </div>
      <img
        className="glb_scroll"
        style={{ zIndex: "9999" }}
        src={scrollGif}
        onClick={onScrollGifClick}
      ></img>
    </div>
  );
};

export default GlbPage;
