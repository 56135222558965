import React, { useEffect, useState } from "react";
import "../assets/css/ourTechMobile.css";
import screen from "../assets/images/iphone_02.png";
import app from "../assets/images/app.png";
import google from "../assets/images/app_2.png";
import Fade from "react-reveal/Fade";
import deinos from "../assets/images/deinos.png";
import boocard from "../assets/images/boo.png";
import hit from "../assets/images/hit_the_bot.png";
import monster from "../assets/images/monster_dude.png";
import nobetci from "../assets/images/nobetci_noter.png";
import para from "../assets/images/para_piyasam.png";
import kinky from "../assets/images/kinky.png";

const OurTechMobile = () => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const vh = window.innerHeight / 100;
      const scrollTop = document.documentElement.scrollTop;
      const start = 1080 * vh;
      const stop = 1150 * vh;

      if (scrollTop > start && scrollTop < stop) {
        const newScale = Math.max(1 - (scrollTop - start) / 400, 0.5);
        setScale(newScale);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="our-mobile mt-7">
        <section className="wrap mt-3">
          <img
            className="zoom"
            style={{ transform: `scale(${scale})` }}
            src={screen}
            alt="Zoomed Image"
          />
        </section>
        <div className="our_acc_mobile">
          <div className="right">
            <div className="app">
              <div className="app_inner">
                <input type="radio" id="tab-1" name="buttons" defaultChecked />
                <label htmlFor="tab-1">
                  <div className="app_inner__tab">
                    <h2>hit the bot</h2>
                    <div className="tab_left">
                      <i className="icon big ion-android-color-palette" />
                      <div className="tab_left__image">
                        <img src={hit}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        <div>
                          {" "}
                          A breath of fresh air in the Brick Breaker category!
                          'Hit The Bot'. Put aside the ordinary bricks, control
                          the ball, destroy the robot, splash the fun!
                        </div>
                      </h3>
                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/lk/app/hit-the-bot-2d-brick-breaker/id1612447091"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.osicrew.hitthebot&hl=tr&gl=US"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" id="tab-2" name="buttons" />
                <label htmlFor="tab-2">
                  <div className="app_inner__tab">
                    <h2>monster dude</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={monster}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        Monster Dude is an amazing platform game with a lot of
                        different worlds to play and so many monsters to play
                        with. Pick your monster Dude and go on a memorable
                        adventure. Explore different worlds and collect coins to
                        get every monster.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/tr/app/monster-dude/id1596774791"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.osicrew.monsterdude"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" id="tab-3" name="buttons" />
                <label htmlFor="tab-3">
                  <div className="app_inner__tab">
                    <h2>para piyasam</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={para}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        Keeping track of the markets and managing your savings
                        is now very easy with the official app of
                        parapiyasam.com, 'Para Piyasam.' With Para Piyasam, you
                        can create a personal wallet and easily monitor your
                        desired currencies, gold, stocks, commodities,
                        cryptocurrencies, and other savings, along with their
                        current values.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/tr/app/para-piyasam-bireysel-c%C3%BCzdan/id1606863861"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.osicrew.parapiyasam"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" id="tab-4" name="buttons" />
                <label htmlFor="tab-4">
                  <div className="app_inner__tab">
                    <h2>kinky</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={kinky}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        Choose your category according to the atmosphere with
                        your friends and partner, experience the fun at peaks.
                        We are with you with an application designed for every
                        person and every mood. Register the players, spin the
                        wheel and the chosen name starts talking or acting! An
                        application that will give a magic touch to every
                        environment you enter with its 5 categories.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/tr/app/kinky-party-game/id1603640620"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.osicrew.drunkbottle"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" id="tab-5" name="buttons" />
                <label htmlFor="tab-5">
                  <div className="app_inner__tab">
                    <h2>nobetci noter</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={nobetci}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        With this application, you can access the address, phone
                        number, and other information of notary public offices
                        on duty in all 81 provinces of Turkey with just one
                        click. This application, available in over 40 cities,
                        allows you to automatically find the nearest notary
                        public offices to your location on the map and also
                        provides directions.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/tr/app/n%C3%B6bet%C3%A7i-noter/id1578663578"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.osicrew.nobetcinoter"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>

                <input type="radio" id="tab-6" name="buttons" />
                <label htmlFor="tab-6">
                  <div className="app_inner__tab">
                    <h2>deinos</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={deinos}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        {" "}
                        In this application, you will encounter a lot of
                        questions about a variety of dinosaurs. If you want to
                        refresh your knowledge and learn new facts, you will
                        have a lot of fun playing the trivia game.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a
                          href="https://apps.apple.com/tr/app/deinos-dinozor-bilgi-yar%C4%B1%C5%9Fmas%C4%B1/id1591608859"
                          target="_blank"
                        >
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://apps.apple.com/tr/app/deinos-dinozor-bilgi-yar%C4%B1%C5%9Fmas%C4%B1/id1591608859"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input type="radio" id="tab-7" name="buttons" />
                <label htmlFor="tab-7">
                  <div className="app_inner__tab">
                    <h2>boocard</h2>
                    <div className="tab_left">
                      <div className="tab_left__image">
                        <img src={boocard}></img>
                      </div>
                    </div>
                    <div className="tab_right">
                      <h3>
                        {" "}
                        Create your business card and easily share it with NFC,
                        QR, or your ID. If you update important information such
                        as your address, phone number, and company name,
                        everyone in your contact list will automatically receive
                        the updates. Protect both nature and your information.
                      </h3>

                      <div className="d-flex app-icon">
                        {" "}
                        <a href="" target="_blank">
                          {" "}
                          <img src={app}></img>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=osi.crew.boocard&hl=tr&gl=US"
                          target="_blank"
                        >
                          {" "}
                          <img src={google}></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTechMobile;
